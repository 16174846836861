import React from 'react'
import styled from '@emotion/styled'
import SkeletonImg from './common/SkeletonImg'
import { pxToEm } from '../services/utils'
import { fontSize3, fontSize7 } from '../services/constant'
import BigCaslonBold from './common/BigCaslonBold'
const Wrapper = styled.div`
        color: #F79E9E;
        text-align: center;
        font-family: "Big Caslon";
        font-size: ${pxToEm(fontSize3)};
        font-style: normal;
        font-weight: 500;
        line-height: normal;
`
const Description = styled.div`
    margin-top:0.75rem;
    font-family: "Gowun Dodum";
    font-size: ${pxToEm(fontSize7)};
    margin-bottom:1.8rem;
`
const InvitaionDescription = () => {
    return (
        <Wrapper>
            <SkeletonImg src={require("../assest/img/Group1.png")} width={400} />
        <BigCaslonBold>
            INVITATION
        </BigCaslonBold>
            <Description>
            사랑을 약속하는 행복한 날에<br/>
            함께해주실 소중한 분들을<br/>
            초대합니다
            </Description>
        </Wrapper>
    )
}

export default InvitaionDescription;