import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import Slider from './Slider'
import { fontSize3 } from '../services/constant'
import BigCaslonBold from './common/BigCaslonBold'

const Wrapper = styled.div`
    margin-top: ${pxToEm(145)};
    font-size: ${pxToEm(fontSize3)};
    text-align: center;
    vertical-align: top;
    font-family: Big Caslon;
    color: #f79e9e;
`
const Info = () => {
    return (
        <Wrapper>
            <BigCaslonBold>

            GALLERY
            </BigCaslonBold>
            <div style={{  marginTop: `${pxToEm(25)}`}} />
            <Slider/>
        </Wrapper>
    )
}

export default Info ;