import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './index1.css'
import React from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";
import { pxToEm } from "../../services/utils";

// import Modal from '../common/Modal'

import Slider1 from './Gallery'

const Wrapper = styled.div`
  .slick-slider {
    // width: ${pxToEm(500)};
    width: 100%;
    max-width: ${pxToEm(720)};
    .slick-list {
      color: transparent !important;
      .slick-track {
        height: ${pxToEm(250)};
      }
      .slick-slide img {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      .slick-slide:not(.slick-center){
        top: ${pxToEm(23)};;
        position: inherit;
        display: flex;
        justify-content: center;
        transform: scale(0.7);
      }
      .slick-slide:not(.slick-center) > div {
        height: ${pxToEm(177)};
        width: ${pxToEm(177)};
      }
      .slick-slide.slick-active.slick-center > div {
        height: 100%;
      }
      .slick-slide > div > div {
        position: relative;
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

import wedding1 from '../../assest/img/1.JPEG';
import wedding2 from '../../assest/img/2.JPEG';
import wedding3 from '../../assest/img/3.JPEG';
import wedding4 from '../../assest/img/4.JPEG';
import wedding5 from '../../assest/img/5.JPEG';
import wedding6 from '../../assest/img/6.JPEG';
import wedding7 from '../../assest/img/7.JPEG';
import wedding8 from '../../assest/img/8.JPEG';
import { useState } from "react";
// import SkeletonImg from '../common/SkeletonImg'
const images = [wedding1, wedding2, wedding3, wedding4, wedding5, wedding6, wedding7, wedding8];

function CenterMode() {

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0', // 중앙 정렬 시 가장자리 간격
    focusOnSelect: true,
    arrows: false
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Wrapper onClick={openModal}>
        <Slider {...settings}>
          {
            // eslint-disable-next-line no-undef
            images.map((path, index) =>(<div key={`wedding-img-${index}`}><img src={path}/> </div>))
          }
        </Slider>
      </Wrapper>
      <Slider1 list={images} isOpen={isModalOpen} onClose={closeModal}></Slider1>
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <SkeletonImg src={require("../../assest/img/map.png")} width={300}/>
      </Modal> */}
    </>
  );
}

export default CenterMode;
