import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import { fontSize2, fontSize8 } from '../services/constant'
const TitleContent = styled.div`
    color: #E36452;
    text-align: center;
    font-family: "FONTSPRING DEMO - Juana Alt Black";
    font-size: ${pxToEm(fontSize8)};
    font-style: normal;
    font-weight: 900;
    line-height: ${pxToEm(fontSize8)};
    margin-top: ${pxToEm(43)};
    width: fit-content;
`
const InfoContent = styled.div`
font-family: Pretendard;
font-size: ${pxToEm(fontSize2)};
font-weight: 400;
display: flex;
justify-content: space-between;
line-height: 0;
margin-top: ${pxToEm(12)};
margin-bottom: ${pxToEm(51)};
`
const Title = () => {
    
    return (
        <>
        <TitleContent>
            we’re<br/>
            getting<br/>
            married
            <InfoContent>
                <span>
                Inhye & Daekyeom
                </span>
                <span>
                Saturday, April 6 2024
                </span>
            </InfoContent>
        </TitleContent>
       
        </>
    )
}

export default Title;