import styled from '@emotion/styled';
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { pxToEm } from '../../services/utils';
import PropTypes from 'prop-types'
import AccountChild from './AccountChild'
const AccordionContainer = styled.div`
  margin: ${pxToEm(20)} 0;
`;

const commCss = css`
    text-align: center;
    font-family: Gowun Dodum;

    display: flex;
    align-items: center;
    justify-content: center;
`
const AccordionItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F3F3F3;
    border-radius: ${pxToEm(10)};
    margin-bottom: ${pxToEm(10)};
    &:last-child {
        margin-bottom: 0;
    }
`;

const AccordionButton = styled.div`
    ${commCss}
    position: relative;
    color: #323b47;
    height: ${pxToEm(50)};
    width: ${pxToEm(360)};
    margin: ${pxToEm(10)} 0;
    border-radius: ${pxToEm(10)};
    cursor: pointer;
`;
const Arrow = styled.span`
    position: absolute;
    right: ${pxToEm(20)};
    color: #999;
    transition: transform 0.3s ease-in-out;
    transform: ${({ isExpanded }) => isExpanded ? 'rotate(180deg)' : 'none'};
`;

const Accordion = ({
    data
}) => {
  
 const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <AccordionContainer>
        {data.map(({title, list}, index) => (
            <AccordionItem key={`accpr-${index}`}>
                <AccordionButton onClick={() => toggleAccordion(index)}>
                    {title}
                    <Arrow isExpanded={index === expandedIndex}>▼</Arrow>
                </AccordionButton>
                {index === expandedIndex && <><AccountChild list={list}>Content of Item 1</AccountChild></>}
            </AccordionItem>
        ))}
    </AccordionContainer>
  );
};

Accordion.propTypes = {
    data: PropTypes.array.isRequired
}
// width prop의 기본값 설정
Accordion.defaultProps = {
    data: [], // 여기에 기본 값을 설정하세요
};

export default Accordion;