import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import { fontSize3, fontSize4, fontSize7 } from '../services/constant'
import BigCaslonBold from './common/BigCaslonBold'
const Wrapper = styled.div`
    text-align: center;
    vertical-align: top;
    font-size: ${pxToEm(fontSize3)};
    font-family: Big Caslon;
    line-height: auto;
    color: #f79e9e;
    margin-top: ${pxToEm(155)};
`
const LocationContent = styled.div`
text-align: center;
  vertical-align: top;
  font-size: ${pxToEm(fontSize7)};
  font-family: Gowun Dodum;
  line-height: auto;
  color: #444444;
  margin: ${pxToEm(14)} 0;
`
const LocationContent2 = styled.div`
margin-top: ${pxToEm(14)};
  font-size: ${pxToEm(fontSize4)};
  font-family: Gowun Dodum;
  line-height: auto;
  color: #444444;
`
const Location = () => {
    
    return (
        <Wrapper>
          <BigCaslonBold>
            LOCATION
          </BigCaslonBold>
            <LocationContent>
            서울 월드컵 컨벤션 2F 임페리얼 볼룸
            </LocationContent>
            <LocationContent2>
            서울 마포구 월드컵로 240 2층<br/>
             (성산동 서울월드컵경기장 서측)
            </LocationContent2>
            <LocationContent2 style={{margin: `${pxToEm(25)} 0`}}>
            Tel 02-3152-7700
            </LocationContent2>
        </Wrapper>
    )
}

export default Location;