import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { pxToEm } from '../../services/utils';
import { fontSize5 } from '../../services/constant';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: ${pxToEm(fontSize5)};
  color: #000;
`;


const Modal = ({ isOpen, onClose, children }) => {
  const [innerIsOpen, setInnerIsOpen] = useState(null);

  useEffect(() => {
    setInnerIsOpen(isOpen);
  }, [isOpen]);

  if (!innerIsOpen) {
    return null; // 모달이 열리지 않으면 null 반환하여 렌더링하지 않음
  }

  return (
    <ModalOverlay>
      <ModalContent>
      <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;