import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { pxToEm } from '../services/utils'
import DwsButton from './common/Button'
import SkeletonImg from './common/SkeletonImg'
import { fontSize1, fontSize2, fontSize4 } from '../services/constant'
const dynamicCss = css`
    text-align: left;
    vertical-align: top;
    font-family: Gowun Dodum;
    line-height: auto;
    color: #2c2c2c;
    `
const Title = styled.div`
    ${dynamicCss}
    font-size: ${pxToEm(fontSize4)};
    margin: ${pxToEm(11)} 0;
    
`
const Description = styled.div`
    ${dynamicCss}
    font-size: ${pxToEm(fontSize2)};
    margin-bottom: ${pxToEm(11)};
`
const ButtonTitle = styled.div`
    ${dynamicCss}
    font-size: ${pxToEm(fontSize1)};
`
const ColumnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${pxToEm(17)};
    // flex-direction: column;
    // flex-wrap: wrap;
    //  gap: ${pxToEm(6)};
`
const CarUl = styled.ul`
    ${dynamicCss}
    font-size: ${pxToEm(fontSize2)};
    list-style-position: inside;
`

const Highlight = styled.span`
    color: #E36452;
`
const Wrapper = styled.div`
    width: ${pxToEm(312)};
`
const Marker = styled.div`
    width: ${pxToEm(12)};
    height: ${pxToEm(12)};
    border-radius: ${pxToEm(6)};
    background-color: ${props => (props.color)};
`
const MarkerLine = styled(Description)`
    display: flex;
    align-items: center;
    & > div {
        margin-right: ${pxToEm(12)};
    }
`
const Blank = styled.div`
    width: ${pxToEm(25)};
`
const aTagAction = (url) => {
    const a = document.createElement('a')
    a.href= url
    a.target = '_blank'
    a.click()
}
const KakaoNavi = () => {
    if (window.Kakao) {
        window.Kakao.Navi.share({
            name: "월드컵컨벤션",
            y: 37.56843152270884,
            x: 126.89613977652142,
            coordType: 'wgs84'
        });
    }
}
const Traffic = () => {
    useEffect(() => {
        if (!window.Kakao.isInitialized()) {
            const KAKAO_KEY = process.env.REACT_APP_KAKAO_KEY;
            window.Kakao.init(KAKAO_KEY);
        };

    }, [])
    return (
        <Wrapper>
        <br/>
        <Title>
            자동차
        </Title>
        <Description>
        원하시는 앱을 선택하시면 길안내가 시작됩니다.
        </Description>
        <ColumnContainer>
                <DwsButton width={102} height={25} onclick={() => {
                    aTagAction("https://map.naver.com/p/directions/-/14126029.9633415,4518658.405209,%EC%9B%94%EB%93%9C%EC%BB%B5%EC%BB%A8%EB%B2%A4%EC%85%98,1902387925,PLACE_POI/-/car?c=15.15,0,0,0,dh")
                }}>
                    <SkeletonImg width={21} height={21} src={require("../assest/img/naver.png")} />
                    <ButtonTitle>네이버</ButtonTitle>
                </DwsButton>
                <DwsButton width={102} height={25} onclick={()=>{
                    aTagAction("https://www.tmap.co.kr/tmap2/mobile/route.jsp?name=월드컵컨벤션&lon=126.89504992016742&lat=37.56854637567611&goalname=월드컵컨벤션&goaladdr=서울%20마포구%20월드컵로%20240&goalid=&goalflag=16&key=ACDF74F09C347613")
                }}>
                    <SkeletonImg width={21} height={21} src={require("../assest/img/tmap.png")} />
                    <ButtonTitle>티맵</ButtonTitle>
                </DwsButton>
                <DwsButton width={102} height={25} onclick={()=>{
                    KakaoNavi()
                }}>
                    <SkeletonImg width={21} height={21} src={require("../assest/img/kakao.png")} />
                    <ButtonTitle>카카오</ButtonTitle>
                </DwsButton>
        </ColumnContainer>
        <CarUl>
            <li>
            월드컵경기장 서문 진입 후 <Highlight></Highlight>
            <span style={{display: 'flex'}}><Blank/><Highlight>서측 1, 2 주차장</Highlight> 이용</span>
            </li>
            <li>
            주차 <Highlight>접수대 사전 등록 후</Highlight> 
            <span style={{display: 'flex'}}><Blank/>출차 (90분 무료)</span>
            </li>
            <li>
            서측 주차장 만차시 주차 요원 안내에 따라
            <span style={{display: 'flex'}}><Blank/> 난지천 공원, 평화의 공원 이용 (3시간 무료)</span>
            </li>
            <li>
            <Highlight>홈플러스 주차장은 무료 주차 불가</Highlight>
            </li>
        </CarUl>
        <br/>
        <Title>
            지하철
        </Title>
        <MarkerLine>
            <Marker color={'#CD7D2F'}/>
            6호선 월드컵경기장 2번 출구
        </MarkerLine>
        <br/>

        <Title>
            버스
        </Title>
        <Description>
        월드컵경기장 서측, 문화비축기지 정류장
        </Description>
        <MarkerLine>
            <Marker color={'#10377D'}/>
            간선: 571, 710, 760
        </MarkerLine>
        <MarkerLine>
            <Marker color={'#409E3D'}/>
            지선: 7019, 7715, 8777
        </MarkerLine>
        <MarkerLine>
            <Marker color={'#DB5D3A'}/>
            광역: 9711
        </MarkerLine>
        <br/>
        <Title style={{fontWeight: 'bold', fontSize: pxToEm(16 * 1.3)}}>
            전세버스
        </Title>
        <Description>
        <li> 4월 6일 오후 12시 30분 황간중학교 후문</li>
        </Description>
        </Wrapper>
    )
}

export default Traffic ;