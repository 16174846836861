import React, { useState } from 'react';
import styled from '@emotion/styled';
import Title from '../Title'
import Location from '../Location';
import Location2 from '../Location2';
import InvitaionDescription from '../InvitaionDescription'
import Family from '../Family';
import DateComponent from '../Date';
import CountDown from '../CountDown'
import DwsButton from '../common/Button'
import Modal from '../common/Modal'
import SkeletonImg from '../common/SkeletonImg'
import Traffic from '../Traffic';
import Info from '../Info';
import Gallery from '../Gallery';
import Account from '../Account';
import Calendar from '../Calendar';
export const EleCenter = styled.div`
 display:flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
`
const PaperBack = styled.div`
background: url(${props => props.image});
background-repeat: no-repeat;
background-size: cover;
display:flex;
align-items: center;
flex-direction: column;
overflow-y: auto;
overflow-x: hidden;
`

const Visual = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };

    const dayCount = () =>{

        const curr = new Date();

        // 2. UTC 시간 계산
        const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);

        // 3. UTC to KST (UTC + 9시간)
        const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
        const kr_curr = new Date(utc + (KR_TIME_DIFF));

      
        const masTime = new Date("2024-04-06");
        const diff = masTime - kr_curr;
    
        const diffDay = Math.floor(diff / (1000*60*60*24));
        return diffDay
    }

    return (
        <PaperBack image={require("../../assest/img/Paper.png")}>
            
            <EleCenter>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', height: 'calc(100vh - 1rem)'}}>
                    <SkeletonImg addStyle={{position: 'absolute', top: 0, width: '100%', display: 'flex', justifyContent: 'center'}} src={require("../../assest/img/Confetti.png")} width={400}/> 
                    {/* {isImgLoaded && <FakeTag/>} */}
                    <EleCenter style={{position: `relative`}}>
                        <Title/>
                        <Location/>    
                    </EleCenter>
                </div>
                <InvitaionDescription/>
                <Family/>
                <DateComponent/>
                <Calendar/>
                <CountDown day={dayCount()}/>
                <Location2/>
                <DwsButton width={150} height={25} onclick={openModal}>
                    약도 이미지 보기
                </DwsButton>
                <Traffic/>
                
                <Info/>
                <Gallery/>
                <Account/>
            </EleCenter >

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <SkeletonImg src={require("../../assest/img/map.png")} width={300}/>
            </Modal>
        </PaperBack>
    );
}
export default Visual;
