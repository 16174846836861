import './App.css';
import React from 'react';
import Visual from './components/Visual';
// import Slider from './components/Slider/Gallery'
function App() {
  return (
    <div className="App">
      {/* <Slider /> */}
        <Visual/>
        
    </div>
  );
}

export default App;
