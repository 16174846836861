import React, { useState } from 'react';
import styled from '@emotion/styled';
import { pxToEm } from '../../services/utils';
import { fontSize4 } from '../../services/constant';

const CalendarWrapper = styled.div`
    width: 100%;
    padding-top: ${pxToEm(46)};
    display: flex;
    align-items: center;
    justify-content: center;
`
const DayBase = styled.div`
    width: ${pxToEm(30)};
    height: ${pxToEm(30)};
    font-size: ${pxToEm(fontSize4)};
    padding: ${pxToEm(8)};
    border-radius: ${pxToEm(30)};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Day = styled(DayBase)`
    ${(props) => props.holiday ? 'color: #f5b2b3;' : 'color: #625d5d;'}
    ${(props) => props.dday && 'background-color: #f59fa0;'}
    ${(props) => props.dday && 'color: white;'}
`
const DayTitle = styled(DayBase)`
    ${(props) => props.i === 0 && 'color: #f5b2b3;'}
`

const DaysOfWeek = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: ${pxToEm(46 * 7)}
`
const Calendar = () => {
  const [date] = useState(new Date('2024-04-01'));

  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];


  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const HoliyDays = [7, 10, 14, 21, 28];
  const renderCalendar = () => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const blanks = Array.from({ length: firstDayOfMonth }, (_, index) => <Day key={`blank-${index}`}></Day>);
    const dday = 6
    const days = Array.from({ length: daysInMonth }, (_, index) => index + 1).map((day) => (
      <Day holiday={HoliyDays.includes(day)} dday={dday === day } key={`day-${day}`}>{day}</Day>
    )); 

    return [...blanks, ...days];
  };

  return (
    <CalendarWrapper>
      <DaysOfWeek>
        {daysOfWeek.map((day, index) => (   
          <DayTitle i={index} key={`day-of-week-${index}`}>{day}</DayTitle>
        ))}
        {renderCalendar()}
      </DaysOfWeek>
    </CalendarWrapper>
  );
};

export default Calendar;