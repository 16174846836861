import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { pxToEm } from '../../services/utils'

import { EleCenter } from '../Visual/index'
import { fontSize2 } from '../../services/constant'
const dynamicStyle = props =>
  css`
  width: ${pxToEm(props.width - 20)};
  height: ${pxToEm(props.height)};
  border-radius: ${pxToEm(9)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${pxToEm(10)};
  gap: ${pxToEm(10)};
  background-color: #ffffff;
  border: ${pxToEm(1)} solid #e2e2e2;
  font-size: ${pxToEm(fontSize2)};
  color: #000000;
  cursor: pointer;
  `

const Wrapper = styled.div`
${dynamicStyle};
`
const Button = (props) => {
    const {children, width, height, onclick} = props;
    return (
        <EleCenter onClick={onclick}>
            <Wrapper width={width} height={height}>
                {children}
            </Wrapper>
        </EleCenter>
      
    )
}

Button.propTypes = {
    children: PropTypes.node.isRequired, // children에 대한 prop-types 검증 추가
    width: PropTypes.number,// children에 대한 prop-types 검증 추가
    height: PropTypes.number,
    onclick: PropTypes.func// children에 대한 prop-types 검증 추가
};
  
// width prop의 기본값 설정
Button.defaultProps = {
    width: 100, // 여기에 기본 값을 설정하세요
    height: 50
};

  
export default Button;