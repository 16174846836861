import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { css } from '@emotion/react'
import { pxToEm } from '../../services/utils';
const SkeletonUICss = props =>
  css`
  width: ${pxToEm(props.width)};
  height: ${pxToEm(props.height)};
  background-color: transparent;
  `
const SkeletonUI = styled.div`
  ${SkeletonUICss}
`;

const Img = (props) => {
    const { width, height, src, alt, addStyle } = props
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
      setImageLoaded(true);setImageLoaded
    };
  
    return (
        <div style={addStyle}>
          {!imageLoaded && <SkeletonUI width={width} height={height} />}
          {/* 실제 이미지가 여기에 들어갑니다. */}
          <img src={src} alt={alt} style={{ display: imageLoaded ? 'block' : 'none', width: `min(${width}px, ${pxToEm(width)})` , height: height && pxToEm(height) }} onLoad={handleImageLoad} />
        </div>
      );
}


Img.propTypes = {
    addStyle: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
};
  
// width prop의 기본값 설정
Img.defaultProps = {
    addStyle: {},
    width: 100, // 여기에 기본 값을 설정하세요
};

export default Img;
