import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import { fontSize4, fontSize6 } from '../services/constant'
const Wrapper = styled.div`
    color: #434343;
    text-align: center;
    font-family: "Gowun Dodum";
    font-size: ${pxToEm(fontSize6)};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: ${pxToEm(13)};
`
const LocationContent = styled.div`
margin: ${pxToEm(26)};
color: #444;
font-size: ${pxToEm(fontSize4)};
`
const Location = () => {
    
    return (
        <Wrapper>
            박인혜 ㆍ 김대겸
            <LocationContent>
            2024년 4월 6일 토요일 오후 4시 50분<br/>
            서울 상암 월드컵 컨벤션 2F 임페리얼 볼룸
            </LocationContent>
        </Wrapper>
    )
}

export default Location;