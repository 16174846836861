import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './index.css'
import styled from '@emotion/styled'
import { pxToEm } from "../../../services/utils";

import PropTypes from 'prop-types'
import { CloseButton } from "../../common/Modal";

const WW = styled.div`
    position: fixed;
    background: #000000cf;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;

`
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  .slick-slider {
    width: 100vw;
    max-width: ${pxToEm(720)};
    height: 100%;
    .slick-list {
      color: transparent !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .slick-track {
        // height: ${pxToEm(250)};
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .slick-slide img {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      .slick-slide:not(.slick-center){
        // top: ${pxToEm(23)};;
        position: inherit;
        display: flex;
        justify-content: center;
        transform: scale(0.85);
      }
      .slick-slide:not(.slick-center) > div {
        // height: ${pxToEm(177)};
        // width: ${pxToEm(400)};
      }
      .slick-slide.slick-active.slick-center > div {
        height: 100%;
      }
      .slick-slide > div > div {
        position: relative;
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }

    .slick-prev {
        left: ${pxToEm(25)};
    }
    .slick-next {
        right: ${pxToEm(25)};
    }
    .slick-dots li button:before, .slick-next:before, .slick-prev:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: slick;
    }
    .slick-next:before, .slick-prev:before {
        color: #fff;
        font-size: ${pxToEm(20)};
        line-height: 1;
        opacity: .75;
        color: #2e8555;
    }
    .slick-prev:before, [dir=rtl] .slick-next:before {
        content: "←";
    }
    .slick-next:before, [dir=rtl] .slick-prev:before {
        content: "→";
    }

    .slick-thumb {
        // bottom: ${pxToEm(-200)};
        bottom: 0;
        display: block;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .slick-dots {
        display: block;
        position: absolute;
        width: 100%;
        margin-left: 0;
        width: 100%;
        // left: 25%;
    }
    .slick-thumb li img {
        filter: grayscale(100%);
    }
    .slick-thumb li.slick-active img {
        filter: grayscale(0);
    }
    .slick-thumb li {
        height: ${pxToEm(45)};
        width: ${pxToEm(60)};
    }
    .slick-dots li, .slick-dots li button {
        cursor: pointer;
    }
    .slick-dots li {
        display: inline-block;
        margin: 0 ${pxToEm(5)};
        padding: 0;
    }
  }
`

function CustomPaging(props) {
  const {list, isOpen, onClose} = props
  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <img src={`${list[i]}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  const [innerIsOpen, setInnerIsOpen] = useState(null);

  useEffect(() => {
    setInnerIsOpen(isOpen);
  }, [isOpen]);

  if (!innerIsOpen) {
    return null; // 모달이 열리지 않으면 null 반환하여 렌더링하지 않음
  }

  

  return (
    <WW>
      <CloseButton style={{color: '#fff', zIndex: 10000}} onClick={onClose}>X</CloseButton>
            <Wrapper>
            <Slider {...settings}>
                {
                    list.map((item, index)=>
                        <div key={`ii-${index}`}>
                            <img src={item} />
                        </div>
                    )
                }
            </Slider>
            </Wrapper>
      
    </WW>
  );
}
CustomPaging.propTypes = {
    list: PropTypes.array,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};
export default CustomPaging;