import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { pxToEm } from '../../services/utils'
import Accordion from './Accordion'
import { fontSize3, fontSize7 } from '../../services/constant'
import BigCaslonBold from '../common/BigCaslonBold'
const Wrapper = styled.div`
    margin-top: ${pxToEm(145)};
    font-size: ${pxToEm(fontSize3)};
    text-align: center;
    vertical-align: top;
    font-family: Big Caslon;
    color: #f79e9e;
`
const Title = styled.div`
    text-align: center;
    vertical-align: top;
    font-size: ${pxToEm(fontSize7)};
    font-family: Gowun Dodum;
    line-height: auto;
    color: #444444;
    margin: ${pxToEm(14)} 0;
`
const commCss = css`
    text-align: center;
    font-family: Gowun Dodum;

    display: flex;
    align-items: center;
    justify-content: center;
`
const Pannel = styled.div`
    ${commCss}
    color: #444444;
    // height: 100%;
    // width: ${pxToEm(360)};
    // background-color: #FAFAFA;
    flex-direction: column;
`
const Account = () => {
    const accountList =[
        {
            title: '신랑측 계좌번호',
            list: [
                {
                    name: '김옥근',
                    accountNumberText: '신협 132-012-983-588',
                    accountNumber: '132-012-983-588'
                },
                {
                    name: '고재숙',
                    accountNumberText: '농협 407089-52-125305',
                    accountNumber: '407089-52-125305'
                },
                {
                    name: '김대겸',
                    accountNumberText: '신한 110-542-499273',
                    accountNumber: '110-542-499273'
                }
            ]
        },
        {
            title: '신부측 계좌번호',
            list: [
                {
                    name: '박종열',
                    accountNumberText: '국민 765-21-0232688',
                    accountNumber: '765-21-0232688'
                },
                {
                    name: '이차복',
                    accountNumberText: '국민 054-21-0572-932',
                    accountNumber: '054-21-0572-932'
                },
                {
                    name: '박인혜',
                    accountNumberText: '국민 054901-04-112587',
                    accountNumber: '054901-04-112587'
                }
            ]
        }
    ]
    return (
        <Wrapper>
            <BigCaslonBold>
            ACCOUNT
            </BigCaslonBold>
           
            <Title>
                마음 전하실 곳
            </Title>
            <Pannel>
                <Accordion data={accountList}/>
            </Pannel>
        </Wrapper>
    )
}

export default Account ;