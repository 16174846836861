import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import { fontSize4 } from '../services/constant'
const Wrapper = styled.div`
    color: #444;
    text-align: center;
    font-family: "Gowun Dodum";
    font-size: ${pxToEm(fontSize4)};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-wrap: wrap;
    & > div {
        display: flex;
        flex-direction: column;
    }
    & > div:nth-child(3) {
        color: #5D5D5D;
    }
    margin-bottom: ${pxToEm(129)};
`
const Family = () => {
    return (
        <Wrapper>
            <div>
                김옥근ㆍ고재숙의<br/>    
                박종열ㆍ이차복의
            </div>
            <div style={{ fontWeight: 'bold', color: 'black' }}>
                <span>&nbsp;첫째아들&nbsp;</span>
                <span>막내딸</span>
            </div>
            <div>
                <span>김대겸</span>
                <span>박인혜</span>
            </div>
        </Wrapper>
    )
}

export default Family;