
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { pxToEm } from '../services/utils'
import { fontSize4 } from '../services/constant'
const Wrapper = styled.div`
    margin-top: ${pxToEm(37)};
    color: #444;
    font-family: "Gowun Dodum";
    font-size: ${pxToEm(fontSize4)};
    
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    justify-content: center;
    &>span {
        color: #F59FA0;
        font-family: "Gowun Dodum";
        font-size: ${pxToEm(fontSize4)};
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`

const CountDown = (props) => {
    return (
        <Wrapper>
            인혜 대겸의
              {
                props.day > 0 ?
                <> <span>
                &nbsp;
                결혼식이 {props.day}일
                &nbsp;
                </span>
                남았습니다.</> :
                <> <span>
                &nbsp;
                결혼식 당일
                &nbsp;
                </span>
                입니다!</>
            }
            
        </Wrapper>
    )
}

CountDown.propTypes = { day:PropTypes.number.isRequired }

export default CountDown;