import styled from '@emotion/styled';
import React from 'react';
import { pxToEm } from '../../services/utils';
import PropTypes from 'prop-types'
const AccordionChildItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: ${pxToEm(358)};
    background-color: #F3F3F3;
    margin-bottom: ${pxToEm(2)};
    &:last-child {
      border-radius: 0 0 ${pxToEm(10)} ${pxToEm(10)};
    }
`;

const Row =  styled.div`
  display: flex;
  height: ${pxToEm(58)};
  width: ${pxToEm(336)};
  margin-bottom: ${pxToEm(2)};
  background-color: #FAFAFA;
  padding: ${pxToEm(10)};
  &:last-child {
    margin-bottom: 0;

    border-radius: 0 0 ${pxToEm(10)} ${pxToEm(10)};
  }
`;

const Column =  styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    align-items: start;
    flex: 1;
    justify-content: space-between;
  }
  &:last-child {
    align-items: end;
    justify-content: space-around;
    & > div:first-child {
      font-size: ${pxToEm(12)};
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f3;
      color: #272727;
      width: ${pxToEm(65)};
      height: ${pxToEm(30)};
      border-radius: ${pxToEm(5)};
      font-weight: bold;
    }
    // & > div:last-child {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   background-color: #fee934;
    //   color: #272727;
    //   width: ${pxToEm(65)};
    //   height: ${pxToEm(30)};
    //   border-radius: ${pxToEm(5)};
    //   font-weight: bold;
    // }
  }
`;
const CopyText = styled.input`
  width: 1px ;
  height: 1px;
  background-color: white;
  border: 0px;
`
const AccountChild = ({
    list
}) => {

  const copy =  (accountNumber) => {
    var copyText = document.getElementById("copyText");
    copyText.value = accountNumber
    // Modern browsers
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText.value)
        .then(() => {
          alert("계좌번호가 복사 되었습니다.");
        })
        .catch(() => {
          alert("계좌번호 복사에 실패하였습니다.");
        });
    } else {
      // Fallback for older browsers
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand("copy");
      
      alert("Text has been copied to the clipboard1234: " + copyText.value);
    }

  }

  return (
    <AccordionChildItem>
       <CopyText type="text" id="copyText"/>
      {
        list.map(({name, accountNumberText,accountNumber}, index) => (
          <Row key={`child-${index}`}>
            <Column>
              <div>{name}</div>
              <div>{accountNumberText}</div>
            </Column>
            <Column>
              <div onClick={() => copy(accountNumber)}>복사하기</div>
              {/* <div>Pay</div> */}
            </Column>
          </Row>
        ))
      }
    </AccordionChildItem>
  );
};

AccountChild.propTypes = {
  list: PropTypes.array.isRequired
}
// width prop의 기본값 설정
AccountChild.defaultProps = {
  list: [], // 여기에 기본 값을 설정하세요
};

export default AccountChild;