import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { pxToEm } from '../services/utils'
import SkeletonImg from './common/SkeletonImg'
import { fontSize3, fontSize4, fontSize7 } from '../services/constant'
import BigCaslonBold from './common/BigCaslonBold'
const dynamicCss = css`
    text-align: center;
    vertical-align: top;
    line-height: auto;
    color: #f79e9e;
    `
const Wrapper = styled.div`
    margin-top: ${pxToEm(115)};
    ${dynamicCss}
    font-size: ${pxToEm(fontSize3)};
    font-family: Big Caslon;

`
const Content = styled.div`
    ${dynamicCss}
    font-size: ${pxToEm(fontSize7)};
    font-family: Gowun Dodum;
    color: #444444;
`
const Description = styled(Content)`
    font-size: ${pxToEm(fontSize4)};
    white-space: pre-wrap;
    margin-top: ${pxToEm(42)};
`

const Info = () => {
    return (
        <Wrapper>
            <BigCaslonBold>
                WEDDING RECEPTION
            </BigCaslonBold>
           
            <Content>
                <div style={{margin: `${pxToEm(14)} 0`}}>
                피로연 안내
                </div>
                
                <SkeletonImg addStyle={{ display: 'flex', justifyContent: 'center' }} width={259} height={90} src={require('../assest/img/Info.png')}></SkeletonImg>
                <Description>
                    거리가 멀어 본식에 참석하시기<br/>

                    어려운 분들을 위해<br/>

                    피로연 자리를 마련하였습니다.<br/><br/>

                    귀한 발걸음으로 두 사람의 앞날을<br/>
                    축하하여 주시면 더 큰 기쁨과<br/>
                    격려가 되겠습니다.<br/><br/>

                    -<br/>

                    충청북도 영동군 황간면 남성리 마을회관<br/>

                    2024년 3월 30일 토요일 오전 11시
                </Description>
            </Content>
        </Wrapper>
    )
}

export default Info ;