import React from 'react'
import styled from '@emotion/styled'
import { pxToEm } from '../services/utils'
import { fontSize3, fontSize7 } from '../services/constant'
import BigCaslonBold from './common/BigCaslonBold'
const Wrapper = styled.div`
    color: #F79E9E;
    text-align: center;
    font-family: "Big Caslon";
    font-size: ${pxToEm(fontSize3)};
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    flex-direction: column;
    justify-content: center;
    & > .time {
        margin-top: ${pxToEm(19)};
        color: #444;
        text-align: center;
        font-family: "Gowun Dodum";
        font-size: ${pxToEm(fontSize7)};
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    margin-bottom: ${pxToEm(37)};
`
const DateComponent = () => {
    return (
        <Wrapper>
            <BigCaslonBold>
                SAVE THE DATE
            </BigCaslonBold>
            <div className="time">
                2024. 04. 06<br/>
                토요일 오후 4시 50분
            </div>
        </Wrapper>
    )
}

export default DateComponent;